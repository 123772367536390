import { AxiosRequestConfig } from "axios"
import SupportExternalAccountDto, { OpenBankingVRPConsentStatusType } from "../api-dtos/support/external-account/support-external-account.dto"
import { RegisterUserRequest } from "../api-dtos/auth/register-user-request.dto.interface"
import { RegisterUserResponse } from "../api-dtos/auth/register-user-response.dto.interface"
import MerchantBalanceResponseBody from "../api-dtos/balance/balance.dto"
import CountryAPIResponse from "../api-dtos/country/countries-response.dto.interface"
import ExternalAccountDto from "../api-dtos/external-account/external-account.dto.interface"
import MerchantChargeDto from "../api-dtos/merchant-charges/merchant-charge-api.dto.interface"
import MerchantChargeRequestBody from "../api-dtos/merchant-charges/merchant-charge-request.dto.interface"
import CreateMerchantRequest from "../api-dtos/merchant/create-merchant-request.dto.interface"
import MerchantApiDto from "../api-dtos/merchant/merchant.dto.interface"
import CreateMerchantResponse from "../api-dtos/merchant/merchant.dto.interface"
import PaginatedMerchantsDto from "../api-dtos/merchant/paginated-merchants.dto.interface"
import PayPointDto from "../api-dtos/pay-point/pay-point.dto.interface"
import UpdatePayPointRequest from "../api-dtos/pay-point/update-payout-request.dto.interface"
import CreatePaymentIntentRequest from "../api-dtos/payment-intent/create-payment-intent.dto"
import { PaymentRequestsResponseDto } from "../api-dtos/payment-requests/list-payment-requests-response.dto.interface"
import PaymentRequestDto from "../api-dtos/payment-requests/payment-request.dto.interface"
import PayoutAPIDto from "../api-dtos/payout/payout.dto.interface"
import UserProfileResponse from "../api-dtos/profile/user-profile-response.dto.interface"
import AppBuildListResponse from "../api-dtos/support/app-builds/app-builds-api.dto.interface"
import MerchantKYCStatusApiDto from "../api-dtos/support/merchant/kyc-status-api.dto.interface"
import SupportMerchantApiDto from "../api-dtos/support/merchant/support-merchant-api.dto.interface"
import SupportMerchantSummaryApiDto from "../api-dtos/support/merchant/support-merchant-summary-api.dto.interface"
import UserReferralsDto from "../api-dtos/support/user/user-referrals.dto.interface"
import TerminalOrderDto from "../api-dtos/terminal-order/terminal-order.dto.interface"
import ListTransactionsAPIDto from "../api-dtos/transaction/list-transactionss.dto.interface"
import TransactionApiDto from "../api-dtos/transaction/transaction.dto.interface"
import Currency from "../api-dtos/types/currency.type"
import OrderDeliveryStatus from "../api-dtos/types/order-delivery-status.type"
import { XeroConnection } from "../api-dtos/xero/xero.dto"
import { invoiceList } from "../common/data"
import { axiosInstance, del, get, post, put } from "./api_helper"
import * as url from "./url_helper"
import { PaginatedResponse } from "../api-dtos/types/paginated-response.type"
import OpenBankingVRPConsentDto from "../api-dtos/open-banking-vrp-consent/open-banking-vrp-consent.dto"
import { PayoutMechanismType } from "../api-dtos/types/payout-mechanism.type"
import { PayoutStatusType } from "../api-dtos/types/payout-status.type"
import { SupportMerchantPayoutDto } from "../api-dtos/payout/support-merchant-payout.dto"
import SupportNewTerminalOrderRequest from "../pages/Merchant/new-terminal-order-request"
import PriceDto from "../api-dtos/types/price.dto"
import SupportDiscountCodeDto from "../pages/DiscountCodes/interfaces/support-discount-code.dto.interface"
import DiscountCodeExistsCheckDto from "../pages/DiscountCodes/interfaces/support-discount-code-exists.dto.interface"
import SupportUpdateDiscountCodeDto from "../pages/DiscountCodes/interfaces/support-discount-code-update-request.interface.dto"
import WorkingCapitalQuoteDto from "../pages/Merchant/working-capital/dtos/working-capital-quote.dto.interface"
import WorkingCapitalCashAdvanceDto from "../pages/Merchant/working-capital/dtos/working-capital-cash-advance.dto.interface"
import WorkingCapitalCashAdvanceApplicationDto from "../pages/Merchant/working-capital/dtos/working-capital-cash-advance-application-body.dto.interface"
import MerchantActivityItemDto from "../pages/Merchant/support-merchant-activity/interfaces/merchant-activity-item-api.dto.interface"
import { PayoutFeesDto } from "./interfaces/support/payouts/payout-fees.dto"
import { MerchantFeesAPIDto } from "../api-dtos/merchant/merchant-fees.api.dto.interface"
import {
  AccountType,
  MerchantAuditLogDto,
  ScheduledPayoutConfig,
} from '../api-dtos/merchant/merchant-audit-log.dto.interface'
import CardHolderDto from "../pages/Merchant/support-merchant-issued-card/interfaces/card-holder.dto.interface"
import CardAuthorizationDto from "../pages/Merchant/support-merchant-issued-card/interfaces/card-authorization.dto.interface"
import CardIssuedDto from "../pages/Merchant/support-merchant-issued-card/interfaces/card-issued.dto.interface"
import { XeroApiLogDto } from "src/api-dtos/xero/xero-api-log.dto"
import MarketingCampaignDeepLinkDto from "src/api-dtos/marketing/marketing-deep-link.dto"
import { DayName } from "src/api-dtos/payout/day-name.type"
import OrderIssueStatus from "src/api-dtos/types/order-issue-status.type"
import OrderReturnStatus from "src/api-dtos/types/order-return-status.type"
import { OfferType, SupportOfferCategoryDto, SupportOfferDto } from "src/api-dtos/offers/offer.dto"
import Country from "src/api-dtos/types/country.type"
import { PaginatedUsersDto } from "src/api-dtos/user/paginated-users.dto.interface"
import { UserApiDto } from "src/api-dtos/user/user-api.dto.interface"

// Gets the logged in user data from local session
const getLoggedInUser = (): RegisterUserResponse | null => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

// Get the logged in support (admin) user
const getLoggedInSupportUser = (): RegisterUserResponse | null => {
  const user = localStorage.getItem("authSupportUser")
  if (user) return JSON.parse(user)
  return null
}

// Gets the users profile
const getUserProfile = (): UserProfileResponse | null => {
  const user = localStorage.getItem("profileUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

const isSupportUserAuthenticated = () => {
  return getLoggedInSupportUser() !== null
}

const handleErrorAndThrow = (err: any) => {
  var message = err.message
  if (err.response && err.response.status) {
    switch (err.response.status) {
      case 404:
        message = "Sorry! the page you are looking for could not be found"
        break
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team"
        break
      case 401:
        message = "Invalid credentials"
        break
      default:
        message = err[1]
        break
    }
  }
  throw message
}

const DownloadRequest = (url: string, fileName: string, config?: AxiosRequestConfig): Promise<void> => {
  return axiosInstance()
    .get<Blob | MediaSource>(url, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) {
        const href = URL.createObjectURL(response.data);

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      }
      throw response.data
    })
    .catch(handleErrorAndThrow)
}

const GetRequest = <ReturnType>(url: string, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .get<ReturnType>(url, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {


      // Cancelled (aborted promise)
      if (err.name === "CanceledError") {
        console.warn(err)
        console.log(err)
        return undefined as unknown as ReturnType
      }

      return handleErrorAndThrow(err)
    })
}

const PostRequest = <ReturnType>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .post<ReturnType>(url, data, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      var message

      console.log('err.response.data', err.response.data)

      if (err.response.data) {

        if (err.response.data.message) {
          throw err.response.data
        }

        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err[1]
            break
        }
      }
      throw message
    })
}

const PutRequest = <ReturnType>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .put<ReturnType>(url, data, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(handleErrorAndThrow)
}

const PatchRequest = <ReturnType>(url: string, data?: any, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .patch<ReturnType>(url, data, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(handleErrorAndThrow)
}

const DeleteRequest = <ReturnType>(url: string, config?: AxiosRequestConfig): Promise<ReturnType> => {
  return axiosInstance()
    .delete<ReturnType>(url, config)
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(handleErrorAndThrow)
}

// is support user signed in: authSupportUser

// Check if a discount code exists
const codeDiscountCodeExist = (code: string): Promise<DiscountCodeExistsCheckDto> => {
  return GetRequest<DiscountCodeExistsCheckDto>(
    `support/discount-codes/exists`, {
    params: {
      code,
    }
  }
  )
}
const checkIfWorkingCapitalEnabled = (
  merchantId: string,
): Promise<{ enabled: boolean }> => {
  return GetRequest<{ enabled: boolean }>(
    `support/working-capital/is-enabled?merchantId=${merchantId}`,
  )
}


// Toggle if working capital is enabled
const toggleWorkingCapitalEnabled = (
  merchantId: string,
  enabled: boolean,
): Promise<boolean> => {
  return enabled ? PostRequest<boolean>(
    `support/working-capital/is-enabled?merchantId=${merchantId}`,
  ) : DeleteRequest<boolean>(
    `support/working-capital/is-enabled?merchantId=${merchantId}`,
  )
}


// Submit an application from a quote
const supportSubmitCashAdvanceApplication = (
  quoteId: string,
  body: WorkingCapitalCashAdvanceApplicationDto,
): Promise<WorkingCapitalQuoteDto> => {
  return PostRequest<WorkingCapitalQuoteDto>(
    `support/working-capital/quote/${quoteId}/apply`,
    body,
  )
}

// get working capital quote
const supportGetWorkingCapitalQuote = (userId: string, merchantId: string): Promise<WorkingCapitalQuoteDto> => {
  return GetRequest<WorkingCapitalQuoteDto>(
    `support/working-capital/quote`, {
    params: {
      userId,
      merchantId,
    },
  }
  )
}

// Get historical cash advances
const supportGetWorkingCapitalCashAdvances = (userId: string): Promise<{ cashAdvances: WorkingCapitalCashAdvanceDto[] }> => {
  return GetRequest<{ cashAdvances: WorkingCapitalCashAdvanceDto[] }>(
    `support/working-capital`, {
    params: { userId },
  }
  )
}

// List repayments on a cash advance
const supportGetWorkingCapitalCashAdvanceRepayments = (cashAdvanceId: string): Promise<ListTransactionsAPIDto> => {
  return GetRequest<ListTransactionsAPIDto>(
    `support/working-capital/cash-advance/${cashAdvanceId}/repayments]`, {
  }
  )
}

// list discount codes (used by marketing)
const listDiscountCodes = (): Promise<{ codes: SupportDiscountCodeDto[] }> => {
  return GetRequest<{ codes: SupportDiscountCodeDto[] }>(
    `support/discount-codes`,
  )
}

// Create a discount code
const createDiscountCode = (body: SupportDiscountCodeDto): Promise<SupportDiscountCodeDto> => {
  return PostRequest<SupportDiscountCodeDto>(
    `support/discount-codes`,
    body,
  )
}

// Update a discount code
const updateDiscountCode = (id: string, body: SupportUpdateDiscountCodeDto): Promise<SupportDiscountCodeDto> => {
  return PutRequest<SupportDiscountCodeDto>(
    `support/discount-codes/${id}`,
    body,
  )
}

// list app builds
const listAppBuilds = (
  offsetId?: string,
  pageSize = 20,
): Promise<AppBuildListResponse> => {
  return GetRequest<AppBuildListResponse>(
    `support/app-build`,
    {
      params: {
        offsetId,
        pageSize,
      }
    }
  )
}

// list merchants
const listMerchants = (query?: string, params?: {
  kybDisabledCharges?: boolean | null
  offsetId?: string,
},
  controller?: AbortController,
): Promise<PaginatedMerchantsDto> => {
  return GetRequest<PaginatedMerchantsDto>(`/support/merchant`, {
    params: {
      query,
      ...params,
    },
    signal: controller?.signal,
  })
}

const listUsers = (query?: string, params?: {
  kybDisabledCharges?: boolean | null
  offsetId?: string,
},
controller?: AbortController
): Promise<PaginatedUsersDto> => {
  return GetRequest<PaginatedUsersDto>(`/support/users`, {
    params: {
      query,
      ...params,
    },
    signal: controller?.signal,
  })
}

const resetMobile2fa = (userId: string) => {
  return PostRequest(`/support/users/${userId}/reset-mobile-2fa`)
}

// List external accounts for VRP consents
function listExternalAccountWithVrpConsents({
  search,
  status,
  payoutSchedule,
  minimumTotalPaymentUnitsSum,
  kybStatus,
  offsetId,
}: {
  search: string | undefined
  status: OpenBankingVRPConsentStatusType | undefined
  payoutSchedule: 't2' | 't1' | 'instant' | undefined
  minimumTotalPaymentUnitsSum: number | undefined
  kybStatus: 'not_reviewed' | 'approved' | 'rejected' | undefined
  offsetId: string | undefined,
}): Promise<PaginatedResponse<SupportExternalAccountDto>> {
  return GetRequest<PaginatedResponse<SupportExternalAccountDto>>('/support/external-accounts', {
    params: {
      ...(offsetId && { offsetId }),
      ...(search && { search }),
      ...(status && { status }),
      ...(payoutSchedule && { payoutSchedule }),
      ...(minimumTotalPaymentUnitsSum && { minimumTotalPaymentUnitsSum }),
      ...(kybStatus && { kybStatus }),
    }
  })
}

function createVrpConsent({
  externalAccountId,
}: {
  externalAccountId: string
}): Promise<{ consent: OpenBankingVRPConsentDto; consentUrl: string }> {
  return PostRequest(`/support/external-accounts/${externalAccountId}/open-banking-vrp-consent`)
}

function authoriseVrpConsent(body: {
  consentIdToken: string
  authorisationCode: string
}): Promise<void> {
  return PostRequest(`/support/open-banking-vrp-consents/authorise`, body)
}

function generateVrpConsentUrl(id: string): Promise<{ id: string; url: string }> {
  return GetRequest(`/support/open-banking-vrp-consents/${id}/hybrid-consent-url`)
}

// payouts
function listPayoutsForMerchant({
  merchantId,
  startDate,
  endDate,
  currencyCode,
  payoutMechanism,
  status,
  offsetId,
}: {
  merchantId: string
  startDate: Date | undefined
  endDate: Date | undefined
  currencyCode: Currency | undefined
  payoutMechanism: PayoutMechanismType | undefined
  status: PayoutStatusType | undefined
  offsetId: string | undefined
}): Promise<PaginatedResponse<SupportMerchantPayoutDto>> {
  return GetRequest(`/support/merchant/${merchantId}/payouts`, {
    params: {
      ...(offsetId && { offsetId }),
      ...(merchantId && { merchantId }),
      ...(startDate && { startDate }),
      ...(endDate && { endDate }),
      ...(currencyCode && { currencyCode }),
      ...(payoutMechanism && { payoutMechanism }),
      ...(status && { status }),
    }
  })
}

function refreshPayoutStatus({
  merchantId,
  payoutId,
}: {
  merchantId: string
  payoutId: string
}): Promise<void> {
  return PostRequest(`/support/merchant/${merchantId}/payouts/${payoutId}/refresh`)
}

function triggerPayout({
  merchantId,
  payoutAmountUnits,
  payoutMechanism,
  excludeFees
}: {
  merchantId: string,
  payoutAmountUnits: number
  payoutMechanism: PayoutMechanismType
  excludeFees: boolean
}): Promise<void> {
  return PostRequest(`/support/merchant/${merchantId}/payouts/trigger`, {
    payoutMechanism,
    payoutAmount: {
      units: payoutAmountUnits,
      currencyCode: 'GBP',
    },
    excludeFees,
  })
}

// Update auto instant payout threshold
const supportUpdateInstantPayoutThreshold = (
  merchantId: string,
  thresholdUnits: number,
): Promise<PayoutFeesDto> => {
  return PostRequest(`/support/merchant/${merchantId}/payouts/instant-payout-threshold`, {
    thresholdUnits,
  })
}

// Fetch instant payout fees
const supportPayoutFees = (
  merchantId: string,
  amountUnits: number,
  controller?: AbortController,): Promise<PayoutFeesDto> => {
  return GetRequest(`/support/merchant/${merchantId}/payouts/fees`, {
    params: {
      amountUnits,
    },
    signal: controller?.signal,
  })
}

// Offer updates
const updateOfferName = (
  offerId: string,
  offerName: string): Promise<void> => {
  return PostRequest(`/support/offers/${offerId}/name`, {
    offerName,
  })
}

const updateOfferDescription = (
  offerId: string,
  offerDescription: string): Promise<void> => {
  return PostRequest(`/support/offers/${offerId}/description`, {
    offerDescription,
  })
}

const updateOfferTerms = (
  offerId: string,
  offerTerms: string): Promise<void> => {
  return PostRequest(`/support/offers/${offerId}/terms`, {
    offerTerms,
  })
}

const updateOfferCategories = (
  offerId: string,
  categories: { id: string }[]): Promise<void> => {
  return PutRequest(`/support/offers/${offerId}/categories`, {
    categories,
  })
}

const updateOfferRewardPercentage  = (
  offerId: string,
  rewardPercentage: number): Promise<void> => {
  return PutRequest(`/support/offers/${offerId}/rewardPercentage`, {
    rewardPercentage,
  })
}

// publish or unpublish a offer:
const publishOffer = (
  offerId: string,
  publish: boolean): Promise<void> => {
  return PutRequest(`/support/offers/${offerId}/publish`, {
    publish,
  })
}

const publishOfferDenomination = (
  offerId: string,
  denominationId: string,
  publish: boolean): Promise<void> => {
  return PutRequest(`/support/offers/${offerId}/denomination/${denominationId}/publish`, {
    publish,
  })
}

// List offers
const supportListOffers = (params?: {
  type?: OfferType,
  country?: Country,
  currency?: Currency,
  pageSize?: number,
  oneUseOnly?: boolean,
  offsetId?: string,
  search?: string,
  enabled?: boolean,
}): Promise<{ data: SupportOfferDto[] }> => {
  return GetRequest<{ data: SupportOfferDto[] }>(`/support/offers`, {
    params,
  })
}

// get offer categories
const supportFetchOfferCategories = (): Promise<{ categories: SupportOfferCategoryDto[] }> => {
  return GetRequest<{ categories: SupportOfferCategoryDto[] }>(`/support/offers/categories`)
}

// get offer
const supportFetchOffer = (offerId: string): Promise<SupportOfferDto> => {
  return GetRequest<SupportOfferDto>(`/support/offers/${offerId}`)
}

// Fetch merchant
const supportFetchMerchant = (merchantId: string): Promise<SupportMerchantApiDto> => {
  return GetRequest(`/support/merchant/${merchantId}`)
}

// Fetch User
const supportFetchUser = (userId: string): Promise<UserApiDto> => {
  return GetRequest(`/support/user/${userId}`)
}

// Fetch merchant summary
const supportFetchMerchantSummary = (merchantId: string): Promise<SupportMerchantSummaryApiDto> => {
  return GetRequest(`/support/merchant/${merchantId}/summary`)
}

// Fetch merchant KYC summary summary
const supportFetchMerchantKYCSummary = (merchantId: string): Promise<MerchantKYCStatusApiDto> => {
  return GetRequest(`/support/merchant/${merchantId}/kyc`)
}

// Force a sync with Stripe
const supportMerchantSyncWithStripe = (merchantId: string): Promise<SupportMerchantApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/stripe/sync`)
}

const supportRequestAdditionalDocs = (merchantId: string, body: {
  disablePayouts?: boolean,
  disableCharges?: boolean,
}): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/request-additional-documents`, body)
}

const supportUnRequestAdditionalDocs = (merchantId: string): Promise<MerchantKYCStatusApiDto> => {
  return DeleteRequest(`/support/merchant/${merchantId}/kyc/request-additional-documents`)
}

const supportToggleCanOrderCardReader = (merchantId: string, canOrderCardReader: boolean): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/can-order-card-reader`, {
    canOrderCardReader,
  })
}

const supportToggleIsUnderReview = (merchantId: string, isUnderReview: boolean): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/under-review`, {
    isUnderReview,
  })
}

const supportToggleOnlinePaymentsEnabled = (merchantId: string, onlinePaymentsEnabled: boolean): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/online-payments-enabled`, {
    onlinePaymentsEnabled,
  })
}

const supportToggle3DS2Payments = (merchantId: string, enforce3DS2: boolean): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/enforce-3ds2`, {
    enforce3DS2,
  })
}

const supportChargeMerchant = (merchantId: string, body: MerchantChargeRequestBody): Promise<MerchantChargeDto> => {
  return PostRequest(`/support/merchant/${merchantId}/charges`, body)
}

const supportFetchMerchantCharges = ({
  merchantId,
  categories,
}: {
  merchantId: string
  categories: string[]
}): Promise<{ charges: MerchantChargeDto[] }> => {
  return GetRequest(`/support/merchant/${merchantId}/charges`, {
    params: {
      categories: categories.join(','),
    }
  })
}

const supportFetchMerchantQuickbooksVendors = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/vendors`)
}

const supportFetchMerchantQuickbooksCustomers = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/customers`)
}

const supportFetchMerchantQuickbooksInvoices = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/invoices`)
}

const supportFetchMerchantQuickbooksTransfers = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/transfers`)
}

const supportFetchMerchantQuickbooksPayments = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/payments`)
}

const supportFetchMerchantQuickbooksPurchases = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/purchases`)
}

const supportFetchMerchantQuickbooksCreditMemos = ({
  merchantId,
}: {
  merchantId: string
}): Promise<any> => {
  return GetRequest(`/support/merchant/${merchantId}/quickbooks/credit-memos`)
}

const supportFetchMerchantQuickbooksTaxCodes = ({
    merchantId,
}: {
    merchantId: string
}): Promise<any> => {
    return GetRequest(`/support/merchant/${merchantId}/quickbooks/tax-codes`)
}

const supportFetchMerchantQuickbooksItems = ({
    merchantId,
}: {
    merchantId: string
}): Promise<any> => {
    return GetRequest(`/support/merchant/${merchantId}/quickbooks/items`)
}

const supportFetchMerchantQuickbooksAccounts = ({
    merchantId,
}: {
    merchantId: string
}): Promise<any> => {
    return GetRequest(`/support/merchant/${merchantId}/quickbooks/accounts`)
}

const supportFetchMerchantQuickbooksCompanyInfo = ({
    merchantId,
}: {
    merchantId: string
}): Promise<any> => {
    return GetRequest(`/support/merchant/${merchantId}/quickbooks/company-info`)
}

const supportFetchMerchantQuickbooksConfig = ({
    merchantId,
}: {
    merchantId: string
}): Promise<any> => {
    return GetRequest(`/support/merchant/${merchantId}/quickbooks/config`)
}

const supportToggle3DS2PaymentLimits = (
  merchantId: string,
  singleTransactionLimitUnits: number,
  dayTransactionLimitUnits: number,
  weekTransactionLimitUnits: number,
): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/enforce-3ds2-limits`, {
    singleTransactionLimitUnits,
    dayTransactionLimitUnits,
    weekTransactionLimitUnits,
  })
}

const supportUpdateInstantPayoutLimits = (
  merchantId: string,
  unApprovedDailyInstantPayoutLimit: number,
  approvedDailyInstantPayoutLimit: number,
  underReviewInstantPayoutLimit: number,
): Promise<MerchantKYCStatusApiDto> => {
  return PostRequest(`/support/merchant/${merchantId}/kyc/instant-payout-limits`, {
    unApprovedDailyInstantPayoutLimit,
    approvedDailyInstantPayoutLimit,
    underReviewInstantPayoutLimit,
  })
}

// support referrals
const supportRedeemReferralCode = (userId: string, code: string): Promise<void> => {
  return PostRequest(`/support/users/${userId}/referrals/redeem/${code}`)
}

const supportListReferralsForUser = (userId: string, merchantId: string): Promise<UserReferralsDto> => {
  return GetRequest(`/support/users/${userId}/referrals`, {
    params: {
      merchantId,
    }
  })
}

const supportNewReward = (
  userId: string,
  merchantId: string,
  body: { amount: PriceDto, reason: string }
): Promise<void> => {
  return PostRequest(`/support/users/${userId}/referrals/discount`, body)
}

// Fetch external accounts
const supportListExternalAccounts = (merchantId: string): Promise<ExternalAccountDto[]> => {
  return GetRequest<ExternalAccountDto[]>(`/support/merchant/${merchantId}/external-accounts`)
}

// Support update merchant payment config details
const supportUpdateMerchantPaymentConfigDetails = (merchantId: string, details: Partial<{
  allowAmex: boolean
  kybDisabledCharges?: boolean | null,
}>): Promise<void> => {
  return PutRequest(`/support/merchant/${merchantId}/payment-config`, details)
}

// Support update merchant details
const supportUpdateMerchantDetails = (merchantId: string, details: Partial<MerchantApiDto>): Promise<void> => {
  return PutRequest(`/support/merchant/${merchantId}`, details)
}

// Fetch a sign in link (support)
const supportGenerateSignInLink = (userId: string): Promise<{ userId: string, magicLink: string }> => {
  return GetRequest(`/support/auth-as/${userId}`)
}

// Cancel a terminal order (support)
const supportCancelTerminalOrder = (terminalOrderId: string): Promise<{}> => {
  return DeleteRequest(`/support/terminals/orders/${terminalOrderId}`)
}

// Fetch transactions
const supportListTransactions = (merchantId: string, pageSize: number = 10): Promise<ListTransactionsAPIDto> => {
  return GetRequest<ListTransactionsAPIDto>(`merchant/${merchantId}/transactions?pageSize=${pageSize}`)
}

// Update terminal serial number
const updateTerminalSerialNumber = (terminalOrderId: string, serialNumber: string): Promise<{}> => {
  return PutRequest(`/support/terminals/orders/${terminalOrderId}/serial`, {
    serialNumber,
  })
}

// Update terminal delivery status
const updateDeliveryStatus = (terminalOrderId: string, status: OrderDeliveryStatus): Promise<{}> => {

  return PostRequest(`/support/terminals/orders/${terminalOrderId}/delivery-status`, {
    status,
  })
}


// Fetch outstanding terminal orders
const listTerminalOrders = (search?: string): Promise<{ terminalOrders: TerminalOrderDto[] }> => {

  return GetRequest<{ terminalOrders: TerminalOrderDto[] }>('/support/terminals/orders', {
    params: {
      search,
    }
  })
}

// fetch support user profile
const fetchUserSupportProfile = (): Promise<UserProfileResponse> => {
  return GetRequest<UserProfileResponse>('/support/me')
}

// Fetch payment requests
const supportListPaymentRequests = (
  merchantId: string,
  offsetPaymentRequestId?: string,
  query?: string,
  pageSize: number = 10): Promise<PaymentRequestsResponseDto> => {

  return GetRequest<PaymentRequestsResponseDto>(
    `support/merchant/${merchantId}/payment-requests`, {
    params: {
      pageSize,
      offsetPaymentRequestId,
      query,
    }
  }
  )
}


const supportListMerchantActivity = (
  merchantId: string,
): Promise<{ items: MerchantActivityItemDto[] }> => {
  return GetRequest<{ items: MerchantActivityItemDto[] }>(
    `support/merchant/${merchantId}/activity`,
  )
}

const supportGetScheduleHistory = (
  merchantId: string,
): Promise<{ audits: MerchantAuditLogDto[] }> => {
  return GetRequest<{ audits: MerchantAuditLogDto[] }>(
    `support/merchant/${merchantId}/schedule/history`,
  )
}


const supportListMarketingDeepLinks = (): Promise<{
  deepLinks: MarketingCampaignDeepLinkDto[]
}> => {
  return GetRequest<{ deepLinks: MarketingCampaignDeepLinkDto[] }>(
    `support/marketing-campaign-deep-links`,
  )
}

const supportCreateMarketingDeepLink = (params: {
  name: string,
  urlSuffix: string,
  desktopFallback?: string,
  marketingTokens: {
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
    adCreativeId?: string
  }
}): Promise<{
  deepLink: MarketingCampaignDeepLinkDto
}> => {
  return PostRequest<{ deepLink: MarketingCampaignDeepLinkDto }>(
    `support/marketing-campaign-deep-links`,
    params,
  )
}

const supportGetCards = (
  merchantId: string,
): Promise<{
  cards: CardIssuedDto[]
  firstActivationDate?: Date
}> => {
  return GetRequest<{
    cards: CardIssuedDto[]
    firstActivationDate?: Date
  }>(
    `support/merchant/${merchantId}/card-issuing/cards`,
  )
}

const supportGetCardholders = (
  merchantId: string,
): Promise<{
  cardholders: CardHolderDto[]
}> => {
  return GetRequest<{ cardholders: CardHolderDto[] }>(
    `support/merchant/${merchantId}/card-issuing/cardholders`,
  )
}

const supportGetCardAuthorizations = (
  merchantId: string,
): Promise<{
  authorizations: CardAuthorizationDto[]
}> => {
  return GetRequest<{ authorizations: CardAuthorizationDto[] }>(
    `support/merchant/${merchantId}/card-issuing/authorizations`,
  )
}

const supportCardGetIsGated = (
  merchantId: string,
): Promise<{
  gated: boolean,
  agreedTerms?: Date
}> => {
  return GetRequest<{ gated: boolean }>(
    `support/merchant/${merchantId}/card-issuing/gated`,
  )
}

const supportCardUpdateIsGated = (
  merchantId: string,
  gated: boolean,
): Promise<void> => {
  return PostRequest<void>(
    `support/merchant/${merchantId}/card-issuing/gated`, {
    gated,
  }
  )
}

const supportGetSchedules = (
  merchantId: string,
): Promise<MerchantFeesAPIDto> => {
  return GetRequest<MerchantFeesAPIDto>(
    `support/merchant/${merchantId}/schedule`,
  )
}

const supportUpdateSchedule = (
  merchantId: string,
  payload: {
    accountType: AccountType | null,
    paused?: boolean,
    config?: ScheduledPayoutConfig | null,
  }
): Promise<MerchantFeesAPIDto> => {
  return PostRequest<MerchantFeesAPIDto>(
    `support/merchant/${merchantId}/schedule`, payload
  )
}

const supportForceManualPayouts = (
    merchantId: string,
    forceManualPayouts: boolean
): Promise<void> => {
  return PostRequest<void>(
      `support/merchant/${merchantId}/payouts/stripe-payout-schedule/paused`, {
          payoutsEnabled: forceManualPayouts,
      }
  )
}

const supportUpdateWeeklyAnchor = (
  merchantId: string,
  anchor: DayName,
): Promise<MerchantFeesAPIDto> => {
  return PostRequest<MerchantFeesAPIDto>(
    `support/merchant/${merchantId}/schedule/weekly-anchor`, {
    anchor,
  }
  )
}

// Support Archive (cancel) a payment request
const supportArchivePaymentRequest = (
  merchantId: string,
  paymentRequestId: string,
): Promise<PaymentRequestDto> => {
  return DeleteRequest<PaymentRequestDto>(
    `support/merchant/${merchantId}/payment-requests/${paymentRequestId}`
  )
}

// fetch payments
const supportFetchPayments = (params: {
  merchantId: string,
  payPointId?: string,
  pageSize: number,
  offsetTransactionId?: string,
  startDate?: string,
  endDate?: string,
  inviteId?: string,
}): Promise<ListTransactionsAPIDto> => {
  return GetRequest<ListTransactionsAPIDto>('/support/payments', { params })
}

// Create a new terminal order
const supportCreateMerchantTerminalOrder = (
  merchantId: string,
  payload: SupportNewTerminalOrderRequest,
): Promise<TerminalOrderDto> => {
  return PostRequest<TerminalOrderDto>(
    `support/merchant/${merchantId}/terminals/orders`,
    payload,
  )
}

// Fetch merchant terminal 
const supportListMerchantTerminals = (merchantId: string): Promise<{ terminalOrders: TerminalOrderDto[] }> => {
  return GetRequest<{ terminalOrders: TerminalOrderDto[] }>(`support/merchant/${merchantId}/terminals/orders`)
}

// Update terminal issue status
const updateTerminalOrderIssue = (merchantId: string, terminalOrderId: string, status: OrderIssueStatus | null, description: string | null): Promise<{}> => {

  return PostRequest(`support/merchant/${merchantId}/terminals/orders/${terminalOrderId}/issue`, {
    status,
    description,
  })
}

// Update terminal return status
const updateTerminalOrderReturn = (merchantId: string, terminalOrderId: string, status: OrderReturnStatus): Promise<{}> => {

  return PostRequest(`support/merchant/${merchantId}/terminals/orders/${terminalOrderId}/return`, {
    status,
  })
}

// update payout schedule
const updatePayoutSchedule = (merchantId: string, schedule: 't2' | 't1' | 'instant'): Promise<{}> => {
  return PostRequest<{}>(`merchant/${merchantId}/payout/schedule`, { schedule })
}

// Fetch payouts
const listPayouts = (merchantId: string): Promise<{ payouts: PayoutAPIDto[] }> => {
  return GetRequest<{ payouts: PayoutAPIDto[] }>(`merchant/${merchantId}/payout/`)
}

// Update an external account 
const updateExternalAccount = (
  merchantId: string,
  externalAccountId: string,
  externalAccount: Partial<ExternalAccountDto>,
): Promise<ExternalAccountDto> => {
  return PutRequest<ExternalAccountDto>(
    `merchant/${merchantId}/external-accounts/${externalAccountId}`,
    externalAccount,
  )
}

// Fetch external accounts
const listExternalAccounts = (merchantId: string): Promise<ExternalAccountDto[]> => {
  return GetRequest<ExternalAccountDto[]>(`merchant/${merchantId}/external-accounts`)
}

// Create an external account
const createExternalAccount = (
  merchantId: string,
  externalAccount: ExternalAccountDto,
): Promise<ExternalAccountDto> => {
  return PostRequest<ExternalAccountDto>(
    `merchant/${merchantId}/external-accounts`,
    externalAccount,
  )
}

// Delete a pay point
const deletePayPoint = (
  merchantId: string,
  payPointId: string,
): Promise<{}> => {
  return DeleteRequest<{}>(`merchant/${merchantId}/pay-points/${payPointId}`)
}

// Create a pay point
const createPayPoint = (
  merchantId: string,
  name: string,
): Promise<PayPointDto> => {
  return PostRequest<PayPointDto>(`merchant/${merchantId}/pay-points`, { name })
}

// Update a pay point
const updatePayPoint = (
  merchantId: string,
  payPointId: string,
  values: UpdatePayPointRequest
): Promise<PayPointDto> => {
  return PutRequest<PayPointDto>(
    `merchant/${merchantId}/pay-points/${payPointId}`,
    values,
  )
}

// Fetch setup intent
const fetchSetupIntent = (): Promise<{ clientSecret: string }> => {
  return GetRequest(`/payments/setup-intent`)
}

// Create payment intent (for payment requests)
const createPaymentIntent = (data: CreatePaymentIntentRequest): Promise<{ clientSecret: string }> => {
  return PostRequest(`/payments/payment-intent`, data)
}

// List pay points
const listMerchantPayPoints = (
  merchantId: string,
): Promise<{ payPoints: PayPointDto[] }> => {
  return GetRequest<{ payPoints: PayPointDto[] }>(
    `merchant/${merchantId}/pay-points`
  )
}

// Archive (cancel) a payment request
const archivePaymentRequest = (
  merchantId: string,
  paymentRequestId: string,
): Promise<PaymentRequestDto> => {
  return DeleteRequest<PaymentRequestDto>(
    `merchant/${merchantId}/payment-requests/${paymentRequestId}`
  )
}

// Disconnect from Xero
const disconnectXero = (
  merchantId: string,
  apiKey: string,
) => {
  return DeleteRequest(
    `merchant/${merchantId}/xero`, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Get list of accounts we can map payments to
const getXeroApiLogs = (
  merchantId: string,
  apiKey: string,
) => {
  return GetRequest<PaginatedResponse<XeroApiLogDto>>(
    `merchant/${merchantId}/xero/logs`, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Get list of accounts we can map payments to
const getXeroAccounts = (
  merchantId: string,
  apiKey: string,
) => {
  return GetRequest<{
    accounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
    feeAccounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
    brandingThemes: {
      code: string
      description: string
      isLinked: boolean
    }[]
  }>(
    `merchant/${merchantId}/xero/accounts`, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Updated selected account
const updatedXeroClearingAccount = (
  merchantId: string,
  apiKey: string,
  code: string
) => {
  return PostRequest<{
    accounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
  }>(
    `merchant/${merchantId}/xero/set-account`, {
    code,
  }, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

const updatedXeroFeeAccount = (
  merchantId: string,
  apiKey: string,
  code: string
) => {
  return PostRequest<{
    accounts: {
      code: string
      description: string
      isLinked: boolean
    }[]
  }>(
    `merchant/${merchantId}/xero/set-fee-account`, {
    code,
  }, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

const updatedXeroBrandingTheme = (
  merchantId: string,
  apiKey: string,
  code: string
) => {
  return PostRequest<void>(
    `merchant/${merchantId}/xero/set-branding-theme`, {
    code,
  }, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Get Xero status (if connected or not)
const getXeroStatus = (
  merchantId: string,
  apiKey: string,
) => {
  return GetRequest<{
    showFees: boolean
    isConnected: boolean
    tenantName?: string
  }>(
    `merchant/${merchantId}/xero/status`, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

const acceptXeroTerms = (
  merchantId: string,
  apiKey: string,
) => {
  return PostRequest<void>(`merchant/${merchantId}/xero/accept-terms`, {}, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Get which tenants we can connect to within Xero
const getXeroTenants = (
  merchantId: string,
  apiKey: string,
  authToken: string,
) => {
  return GetRequest<XeroConnection[]>(
    `merchant/${merchantId}/xero/connections`, {
    params: {
      authToken,
    },
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Update xero auth token
const updateXeroAuthtoken = (
  merchantId: string,
  apiKey: string,
  idToken: string,
  authToken: string,
  refresh: string,
  sessionState: string,
  tenantId: string,
): Promise<PaymentRequestDto> => {
  return PostRequest<PaymentRequestDto>(
    `merchant/${merchantId}/xero/token`, {
    idToken,
    authToken,
    refresh,
    sessionState,
    tenantId,
  }, {
    headers: {
      'x-api-key': apiKey,
    }
  })
}

// Create a payment request
const createPaymentRequest = (
  merchantId: string,
  amountUnits: number,
  description: string,
  currency: Currency,
): Promise<PaymentRequestDto> => {
  return PostRequest<PaymentRequestDto>(
    `merchant/${merchantId}/payment-requests`, {
    amount: {
      units: amountUnits,
      currencyCode: currency,
    },
    customerFacingDescription: description,
  })
}

// Fetch payment requests
const listPaymentRequests = (
  merchantId: string,
  offsetPaymentRequestId?: string,
  query?: string,
  pageSize: number = 10): Promise<PaymentRequestsResponseDto> => {

  return GetRequest<PaymentRequestsResponseDto>(
    `merchant/${merchantId}/payment-requests`, {
    params: {
      pageSize,
      offsetPaymentRequestId,
      query,
    }
  }
  )
}


// Fetch Balance
const getBalance = (merchantId: string): Promise<MerchantBalanceResponseBody> => {
  return GetRequest<MerchantBalanceResponseBody>(`merchant/${merchantId}/balance`)
}

//download transactions

const downloadTransactions = (
  fileName: string,
  merchantId: string,
  offsetTransactionId?: string,
  startDate?: Date,
  endDate?: Date,
  format: 'excel' | 'csv' = 'csv',
) => {
  return DownloadRequest(
    `merchant/${merchantId}/transactions`,
    fileName,
    {
      params: {
        offsetTransactionId,
        startDate,
        endDate,
        format,
      },
      responseType: 'blob',
    },
  )
}

// Fetch transactions
const listTransactions = (
  merchantId: string,
  offsetTransactionId?: string,
  startDate?: Date,
  endDate?: Date,
  format: 'json' | 'excel' | 'csv' = 'json',
  query?: string,
): Promise<ListTransactionsAPIDto> => {
  return GetRequest<ListTransactionsAPIDto>(
    `merchant/${merchantId}/transactions`,
    {
      params: {
        offsetTransactionId,
        startDate,
        endDate,
        format,
        query,
      }
    }
  )
}

// Create refund
const createRefund = (
  merchantId: string,
  transactionId: string,
  body: { amount: number },
): Promise<TransactionApiDto> => {
  return PostRequest<TransactionApiDto>(`merchant/${merchantId}/transactions/${transactionId}/refund`, body)
}


// Create merchant
const createMerchant = (merchant: CreateMerchantRequest): Promise<CreateMerchantResponse> => {
  return PostRequest<CreateMerchantResponse>(url.POST_MERCHANT, merchant)
}

// fetch countries
const fetchCountries = (): Promise<CountryAPIResponse[]> => {
  return GetRequest<CountryAPIResponse[]>(url.GET_COUNTRIES)
}



// fetch user profile
const fetchUserProfile = (): Promise<UserProfileResponse> => {
  return GetRequest<UserProfileResponse>(url.GET_USER)
}

// Login Method
const postFakeLogin = (data: any) => post(url.POST_FAKE_LOGIN, data)

// postForgetPwd
const postFakeForgetPwd = (data: any) => post(url.POST_FAKE_PASSWORD_FORGET, data)

// Edit profile
const postJwtProfile = (data: any) => post(url.POST_EDIT_JWT_PROFILE, data)

const postFakeProfile = (data: any) => post(url.POST_EDIT_PROFILE, data)

// Register Method
const postUser = (data: RegisterUserRequest): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(url.POST_USER, data)
}

// Request login otp
const requestOTP = (email: string): Promise<{ userId: string }> => {
  return PostRequest<{ userId: string }>(url.POST_USER_REQUEST_MAGIC_LINK, { email, method: 'code' })
}

// Validate OTP sent via email

const validateOTP = (userId: string, otp: string): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(url.POST_USER_VALIDATE_MAGIC_LINK, {
    userId,
    otp,
  })
}

// postForgetPwd
const postJwtForgetPwd = (data: any) => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data)

const postSocialLogin = async (data: any): Promise<RegisterUserResponse> => {
  return PostRequest<RegisterUserResponse>(`/support/auth/login/google`, {
    ...data,
  })
}

// get Products
export const getProducts = () => get(url.GET_PRODUCTS)

// get Product detail
export const getProductDetail = (id: number) =>
  get(`${url.GET_PRODUCTS_DETAIL}/${id}`, { params: { id } })

// get Events
export const getEvents = () => get(url.GET_EVENTS)

// add Events
export const addNewEvent = (event: any) => post(url.ADD_NEW_EVENT, event)

// update Event
export const updateEvent = (event: any) => put(url.UPDATE_EVENT, event)

// delete Event
export const deleteEvent = (event: any) =>
  del(url.DELETE_EVENT, { headers: { event } })

// get Categories
export const getCategories = () => get(url.GET_CATEGORIES)

// get chats
export const getChats = () => get(url.GET_CHATS)

// get groups
export const getGroups = () => get(url.GET_GROUPS)

// get Contacts
export const getContacts = () => get(url.GET_CONTACTS)

// get messages
export const getMessages = (roomId = "") =>
  get(`${url.GET_MESSAGES}/${roomId}`, { params: { roomId } })

// post messages
export const addMessage = (message: any) => post(url.ADD_MESSAGE, message)

// get orders
export const getOrders = () => get(url.GET_ORDERS)

// add order
export const addNewOrder = (order: any) => post(url.ADD_NEW_ORDER, order)

// update order
export const updateOrder = (order: any) => put(url.UPDATE_ORDER, order)

// delete order
export const deleteOrder = (order: any) =>
  del(url.DELETE_ORDER, { headers: { order } })

// get cart data
export const getCartData = () => get(url.GET_CART_DATA)

// get customers
export const getCustomers = () => get(url.GET_CUSTOMERS)

// add CUSTOMER
export const addNewCustomer = (customer: any) => post(url.ADD_NEW_CUSTOMER, customer)

// update CUSTOMER
export const updateCustomer = (customer: any) => put(url.UPDATE_CUSTOMER, customer)

// delete CUSTOMER
export const deleteCustomer = (customer: any) =>
  del(url.DELETE_CUSTOMER, { headers: { customer } })

// get shops
export const getShops = () => get(url.GET_SHOPS)

// get wallet
export const getWallet = () => get(url.GET_WALLET)

// get crypto order
export const getCryptoOrder = () => get(url.GET_CRYPTO_ORDERS)

// get invoices
export const getInvoices = () => invoiceList

// get invoice details
export const getInvoiceDetail = (id: number) =>
  get(`${url.GET_INVOICE_DETAIL}/${id}`, { params: { id } })

// get project
export const getProjects = () => get(url.GET_PROJECTS)

// get project details
export const getProjectsDetails = (id: number) =>
  get(`${url.GET_PROJECT_DETAIL}/${id}`, { params: { id } })

// get tasks
export const getTasks = () => get(url.GET_TASKS)

// get contacts
export const getUsers = () => get(url.GET_USERS)

// add user
export const addNewUser = (user: any) => post(url.ADD_NEW_USER, user)

// update user
export const updateUser = (user: any) => put(url.UPDATE_USER, user)

// delete user
export const deleteUser = (user: any) => del(url.DELETE_USER, { headers: { user } })

/** PROJECT */
// add user
export const addNewProject = (project: any) => post(url.ADD_NEW_PROJECT, project)

// update user
export const updateProject = (project: any) => put(url.UPDATE_PROJECT, project)

// delete user
export const deleteProject = (project: any) =>
  del(url.DELETE_PROJECT, { headers: { project } })

// export const getUserProfile = () => get(url.GET_USER_PROFILE)

export {
    // Generic helpers
    GetRequest,
    PostRequest,
    PutRequest,
    PatchRequest,
    DeleteRequest,
    DownloadRequest,

    // Payouts
    listPayoutsForMerchant,
    listPayouts,
    updatePayoutSchedule,
    refreshPayoutStatus,
    triggerPayout,

    // Pay points
    deletePayPoint,
    createPayPoint,
    listMerchantPayPoints,
    updatePayPoint,

    // Merchant manages their Xero integration:
    disconnectXero,
    getXeroStatus,
    getXeroAccounts,
    getXeroApiLogs,
    updatedXeroClearingAccount,
    updatedXeroFeeAccount,
    updatedXeroBrandingTheme,
    updateXeroAuthtoken,
    getXeroTenants,
    acceptXeroTerms,

    // External accounts
    createExternalAccount,
    listExternalAccounts,
    updateExternalAccount,

    // support
    postSocialLogin,
    fetchUserSupportProfile,
    isSupportUserAuthenticated,
    getLoggedInSupportUser,
    supportCancelTerminalOrder,
    supportGenerateSignInLink,
    supportFetchMerchantSummary,

    // support offers
    updateOfferCategories,
    supportListOffers,
    supportFetchOffer,
    supportFetchOfferCategories,
    publishOffer,
    updateOfferRewardPercentage,
    publishOfferDenomination,
    updateOfferName,
    updateOfferDescription,
    updateOfferTerms,

    // Marketing ops
    supportListMarketingDeepLinks,
    supportCreateMarketingDeepLink,

    //card issuing
    supportCardGetIsGated,
    supportCardUpdateIsGated,
    supportGetCardholders,
    supportGetCardAuthorizations,
    supportGetCards,
    supportGetSchedules,
    supportUpdateSchedule,
    supportForceManualPayouts,
    supportGetScheduleHistory,
    supportUpdateWeeklyAnchor,
    supportFetchMerchant,
    supportUpdateMerchantDetails,
    supportUpdateMerchantPaymentConfigDetails,
    supportFetchPayments,
    supportListTransactions,
    supportChargeMerchant,
    supportFetchMerchantCharges,
    supportFetchMerchantKYCSummary,
    supportRequestAdditionalDocs,
    supportUnRequestAdditionalDocs,
    supportNewReward,
    supportMerchantSyncWithStripe,
    supportPayoutFees,
    supportUpdateInstantPayoutThreshold,

    // open banking
    listExternalAccountWithVrpConsents,
    authoriseVrpConsent,
    createVrpConsent,
    generateVrpConsentUrl,

    //Auth session
    getLoggedInUser,
    isUserAuthenticated,

    // Terminal order management (support)
    listTerminalOrders,
    updateDeliveryStatus,
    updateTerminalSerialNumber,

    // Dep ops
    listAppBuilds,

    // Support merchants
    listMerchants,
    supportListExternalAccounts,
    supportToggleCanOrderCardReader,
    supportToggleOnlinePaymentsEnabled,
    supportToggle3DS2Payments,
    supportToggle3DS2PaymentLimits,
    supportRedeemReferralCode,
    supportListReferralsForUser,
    supportToggleIsUnderReview,
    supportUpdateInstantPayoutLimits,

    // Support payment links
    supportListPaymentRequests,
    supportArchivePaymentRequest,

    // Support merchant activity
    supportListMerchantActivity,

    // Support working capital
    supportSubmitCashAdvanceApplication,
    supportGetWorkingCapitalQuote,
    supportGetWorkingCapitalCashAdvances,
    supportGetWorkingCapitalCashAdvanceRepayments,
    checkIfWorkingCapitalEnabled,
    toggleWorkingCapitalEnabled,

    // Support discount codes
    listDiscountCodes,
    codeDiscountCodeExist,
    createDiscountCode,
    updateDiscountCode,

    // Merchant terminals
    supportListMerchantTerminals,
    supportCreateMerchantTerminalOrder,
    updateTerminalOrderIssue,
    updateTerminalOrderReturn,
    getBalance,
    fetchSetupIntent,
    createPaymentIntent,
    createRefund,
    downloadTransactions,
    postUser,
    fetchUserProfile,
    postFakeLogin,
    postFakeProfile,
    postFakeForgetPwd,
    validateOTP,
    postJwtForgetPwd,
    postJwtProfile,
    requestOTP,
    fetchCountries,
    createMerchant,
    listTransactions,
    getUserProfile,
    listPaymentRequests,
    createPaymentRequest,
    archivePaymentRequest,
    listUsers,
    supportFetchUser,
    resetMobile2fa,

    //Quickbooks
    supportFetchMerchantQuickbooksVendors,
    supportFetchMerchantQuickbooksCustomers,
    supportFetchMerchantQuickbooksInvoices,
    supportFetchMerchantQuickbooksTransfers,
    supportFetchMerchantQuickbooksPayments,
    supportFetchMerchantQuickbooksPurchases,
    supportFetchMerchantQuickbooksCreditMemos,
    supportFetchMerchantQuickbooksTaxCodes,
    supportFetchMerchantQuickbooksItems,
    supportFetchMerchantQuickbooksAccounts,
    supportFetchMerchantQuickbooksCompanyInfo,
    supportFetchMerchantQuickbooksConfig,
}
