import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import TimeAgo from 'javascript-time-ago'

import { Link } from "react-router-dom";

import {
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Table,
    Container,
    Badge,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
    supportListMarketingDeepLinks,
} from "../../helpers/lopay_api_helper";

// Register timezone 
import en from 'javascript-time-ago/locale/en.json'
import ReactTimeAgo from "react-time-ago";
import MarketingCampaignDeepLinkDto from "src/api-dtos/marketing/marketing-deep-link.dto";
import CreateMarketingDeepLinksModal from './components/create-marketing-deep-links-model.component'

try {
    TimeAgo.addDefaultLocale(en)
} catch (e) {
    console.error(e)
}

interface MarketingDeepLinksPageProps {
    history: any
}

const MarketingDeepLinksPage = ({ history }: MarketingDeepLinksPageProps) => {
    const [deepLinks, setDeepLinks] = useState<MarketingCampaignDeepLinkDto[]>([])
    const [isModalOpen, setIsModalOpen] = useState(false)

    const loadDeepLinks = async () => {
        const deepLinksResponse = await supportListMarketingDeepLinks()
        setDeepLinks(deepLinksResponse.deepLinks)
    }

    useEffect(() => {
        loadDeepLinks()
        // eslint-disable-next-line
    }, [])

    const toggleOpen = (open: boolean) => {
        setIsModalOpen(open)
    }

    const onCreate = (entity: MarketingCampaignDeepLinkDto) => {
        setDeepLinks((deepLinks) => [entity, ...deepLinks])
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Marketing Deep Links</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Marketing" breadcrumbItem="Marketing Deep links" />

                    <Row>
                        <Col xs={12}>
                            <Card>
                                <CardHeader>
                                    <div className="row align-ite  ms-center">
                                        <div className="col-md-6">
                                            <p className="card-title-desc">
                                                Deep links which are used to attribute registrations to marketing campaigns, such as digital ads.
                                            </p>
                                        </div>

                                        <div className="col-md-6">
                      <div className="d-flex flex-wrap align-items-center justify-content-end gap-2 mb-3">
                        <div>
                          <Link
                            onClick={(e) => {
                              e.preventDefault();
                              setIsModalOpen(true);
                            }}
                            to="#"
                            className="btn btn-primary"
                          >
                            <i className="bx bx-plus me-1"></i> New Deep Link
                          </Link>
                        </div>
                      </div>
                    </div>

                                    </div>
                                </CardHeader>

                                <CardBody className="p-4">
                                    <div className="table-responsive">
                                        <Table className="table table-striped mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Name</th>
                                                    <th>Link</th>
                                                    <th>Created by</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    deepLinks.map((deepLink) => {
                                                        return (<tr onClick={() => {
                                                            // open payout details (included payments etc.)
                                                        }} key={deepLink.id}>
                                                            <td>
                                                                <ReactTimeAgo date={new Date(deepLink.createdAt)} locale="en-US" />
                                                            </td>
                                                            <td>
                                                                {deepLink.name}
                                                            </td>
                                                            <td>
                                                                <a href={deepLink.url} target="_blank" rel="noreferrer">{deepLink.url}</a>
                                                                <br/>
                                                                {
                                                                    deepLink.desktopFallback && (
                                                                        <Badge className="me-2 bg-warning">
                                                                            Desktop fallback: {deepLink.desktopFallback}
                                                                        </Badge>
                                                                    )
                                                                }
                                                                {
                                                                    deepLink.marketingTokens.utmSource && (
                                                                        <Badge className="me-2 bg-info">
                                                                            UTM Source: {deepLink.marketingTokens.utmSource}
                                                                        </Badge>
                                                                    )
                                                                }
                                                                {
                                                                    deepLink.marketingTokens.utmMedium && (
                                                                        <Badge className="me-2 bg-info">
                                                                            UTM Medium: {deepLink.marketingTokens.utmMedium}
                                                                        </Badge>
                                                                    )
                                                                }
                                                                {
                                                                    deepLink.marketingTokens.utmCampaign && (
                                                                        <Badge className="me-2 bg-info">
                                                                            UTM Campaign: {deepLink.marketingTokens.utmCampaign}
                                                                        </Badge>
                                                                    )
                                                                }
                                                                {
                                                                    deepLink.marketingTokens.adCreativeId && (
                                                                        <Badge className="me-2 bg-info">
                                                                            Ad Creative ID: {deepLink.marketingTokens.adCreativeId}
                                                                        </Badge>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {deepLink.createdBy.firstName} {deepLink.createdBy.lastName}
                                                            </td>
                                                        </tr>)
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <CreateMarketingDeepLinksModal
                        open={isModalOpen}
                        toggleOpen={toggleOpen}
                        onCreate={onCreate}
                    />

                </Container>
            </div>
        </React.Fragment>
    );
};

export default MarketingDeepLinksPage;
