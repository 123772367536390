import { PayoutScheduleType } from '../api-dtos/payout/payout-schedule.type.interface'
import PriceDto from '../api-dtos/types/price.dto'
import getSymbolFromCurrency from 'currency-symbol-map'
import { AccountType, ScheduledPayoutConfig } from '../api-dtos/merchant/merchant-audit-log.dto.interface'

const priceFormat = (price: PriceDto) => {
   const currency = currencyFormat(price.units)
   const symbol = getSymbolFromCurrency(price.currencyCode.toLocaleUpperCase())
   return symbol + currency
}

const currencyFormat = (units: number) => {
   return (units / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

const percentageFormat = (float: number, fractionDigits: number = 2) => {
  return `${(float * 100).toFixed(fractionDigits)}%`;
}

const convertToUnits = (amountString: string) => {
    let truncatedDecimalString = Number(amountString).toFixed(2)
    let cleanedString = truncatedDecimalString.replace(/[^\d]/g, '')
    const units = parseInt(cleanedString, 10)
    return units
}

const convertCamelCaseToWords = (camelCaseString: string): string => {
  const words = camelCaseString
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2') // Add space before groups of capital letters
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))

  return words.join(' ')
}

const getOrdinalSuffix = (day: number) => {
    const stringNumber = String(day)
    const lastDigit = stringNumber[stringNumber.length - 1]
    // Accounting for 11, 12 and 13 ending in `th`
    const penultimateDigit =
        stringNumber.length > 1
            ? stringNumber[stringNumber.length - 2]
            : undefined

    switch (lastDigit) {
        case '1':
            if (!penultimateDigit || penultimateDigit !== '1') return 'st'
            else return 'th'
        case '2':
            if (!penultimateDigit || penultimateDigit !== '1') return 'nd'
            else return 'th'
        case '3':
            if (!penultimateDigit || penultimateDigit !== '1') return 'rd'
            else return 'th'
        default:
            return 'th'
    }
}

const getPaymentPayoutScheduleFriendlyName = (
   schedule?: PayoutScheduleType,
   config?: ScheduledPayoutConfig | null,
): string => {
    switch (schedule) {
        case 'cardIssuing':
            const payoutSchedule = config && (config.type === 'weekly' || config.type === 'monthly')
                ? `, ${config.type === 'weekly'
                        ? config.weeklyAnchor
                        : `${config.monthlyAnchor}${getOrdinalSuffix(config.monthlyAnchor)}`
                    }`
                : ''
            return `Lopay card ${config ? `(${config.type} payout${payoutSchedule})` : ''}`
        case 'instant':
            return 'Instant'
        case 't1':
            return 'Next Day'
        case 't2':
            return 'Weekly'
        default:
            return 'Unknown'
    }
}

export const getAccountTypeFriendlyName = (
    accountType?: AccountType | null,
    config?: ScheduledPayoutConfig | null,
): string => {
    const payoutSchedule = config && (config.type === 'weekly' || config.type === 'monthly')
        ? `, ${config.type === 'weekly'
            ? config.weeklyAnchor
            : `${config.monthlyAnchor}${getOrdinalSuffix(config.monthlyAnchor)}`
        }`
        : ''

    const accountTypeNameMap: Record<AccountType, string> = {
        [AccountType.Essential]: 'Essential',
        [AccountType.Standard]: 'Standard',
        [AccountType.Premium]: 'Premium',
    }

    const name = accountType
        ? accountTypeNameMap[accountType]
        : '[not selected]'

    return `${name} ${config ? `(${config.type} payout${payoutSchedule})` : ''}`
}

const buildStripeDashboardURL = (path: string) => {
  const isProduction = process.env.REACT_APP_API_HOST?.startsWith('https://api.lopay.com')

  return new URL(`https://dashboard.stripe.com${isProduction ? '' : '/test'}${path}`).toString()
}

function stripEmptyStringsValuesShallow(obj: {
    [key: string]: any
}) {
    return Object.keys(obj).reduce((formattedObj, k) => {
        if (typeof obj[k] !== 'string' || obj[k] !== '') {
            formattedObj[k] = obj[k]
        }

        return formattedObj
    }, {} as { [key: string]: any })
}

export {
    currencyFormat,
    priceFormat,
    percentageFormat,
    convertToUnits,
    getPaymentPayoutScheduleFriendlyName,
    buildStripeDashboardURL,
    convertCamelCaseToWords,
    stripEmptyStringsValuesShallow,
}
