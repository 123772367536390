/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import MetaTags from 'react-meta-tags'
import styled from 'styled-components'
import QRCode from 'qrcode.react'

//redux
import { useDispatch } from 'react-redux'
import classnames from 'classnames'
import {
    Alert,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader,
    Container,
    Badge,
    TabContent,
    TabPane,
    NavItem,
    CardText,
    Nav,
    NavLink,
    CardTitle,
    Form,
    Label,
    Input,
    FormGroup,
    Modal,
    Table,
} from 'reactstrap'
import { AvForm } from 'availity-reactstrap-validation'

//Import Breadcrumb
import { fetchProfile } from '../../store/actions'
import {
    supportFetchMerchant,
    supportFetchMerchantKYCSummary,
    supportFetchMerchantSummary,
    supportGenerateSignInLink,
    supportListExternalAccounts,
    supportMerchantSyncWithStripe,
    supportUpdateMerchantPaymentConfigDetails,
} from '../../helpers/lopay_api_helper'
import ReactTimeAgo from 'react-time-ago'
// eslint-disable-next-line
import SupportMerchantSummaryApiDto from '../../api-dtos/support/merchant/support-merchant-summary-api.dto.interface'
import { priceFormat } from '../../helpers/utils'
import SupportMerchantApiDto from '../../api-dtos/support/merchant/support-merchant-api.dto.interface'
import MerchantKYCStatus from '../../api-dtos/support/merchant/kyc-status-api.dto.interface'
import ExternalAccountDto from '../../api-dtos/external-account/external-account.dto.interface'
import ExternalAccountOpenBankingVrpTable from '../ExternalAccountOpenBankingVRPs/external-account-open-banking-vrp-table/external-account-open-banking-vrp-table.component'
import PayoutTable from '../Payouts/payout-table/payout-table.component'
import MerchantDiscountReferrals from './merchant-discounts-referrals'
import MerchantWorkingCapital from './working-capital/merchant-working-capital'
import TriggerPayout from '../Payouts/trigger-payout/trigger-payout.component'
import PaymentRequestsSupportComponent from './support-payment-requests/payment-requests.component'
import RecentActivitySupportComponent from './support-merchant-activity/support-merchant-activity.component'
import LopayCardSupportComponent from './support-merchant-issued-card/support-merchant-issued-card.component'
import MerchantScheduleComponent from './support-merchant-schedule/support-merchant-schedule.component'
import SupportMerchantAccountSetup from './support-merchant-account-setup/support-merchant-account-setup.component'
import AccountSetupProgress from './support-merchant-account-setup/components/account-setup-progress.component'
import SupportMerchantRewardActivity from './support-merchant-reward-activity/support-merchant-reward-activity.component'
import SupportMerchantRewards from './support-merchant-rewards/support-merchant-rewards.component'
import SupportMerchantRewardChallenges from './support-merchant-reward-challenges/support-merchant-reward-challenges.component'
import SupportMerchantHardware from './support-merchant-hardware/support-merchant-hardware'
import SupportMerchantTeam from './support-merchant-team/support-merchant-team.component'
import SupportMerchantCharges from './support-merchant-charges/support-merchant-charges.component'
import MerchantRiskScoreComponent from './merchant-risk-score.component'
import SupportMerchantKYC from './support-merchant-kyc/support-merchant-kyb.component'
import SupportMerchantPayments from './support-merchant-payments/support-merchant-payments.component'
import PaymentMap from './support-merchant-kyc/components/PaymentMap/PaymentMap'
import LinkedMerchants from './support-merchant-profile/components/linked-merchants/linked-merchants.component'
import MerchantPageNavbar, { MerchantTab } from './components/merchant-page-navbar/merchant-page-navbar.component'
import MerchantCashAdvances from './support-merchant-cash-advances/merchant-cash-advances'
import SupportMerchantQuickbooks from './support-merchant-quickbooks/support-merchant-quickbooks.component'
import SupportMerchantKYB from './support-merchant-kyb/support-merchant-kyb.component'
import MerchantBusinessProfile
    from './support-merchant-profile/components/business-profile/merchant-business-profile.component'

type KYCTabNameType = 'review' | 'payments' | 'kyb'

type RewardsTabNameType = 'activity' | 'challenges' | 'rewards'

const CardHeaderJustified = styled(CardHeader)`
    display: flex;
    justify-content: space-between;
`

const CardSubHeader = styled.div``

const Spacer = styled.div`
    margin-left: 20px;
    margin-right: 20px;
`

interface PageProps {
    google: Object
    history: any
    match: {
        params: {
            merchantId: string
        }
    }
}

const MerchantPage = (props: PageProps) => {
    const merchantId = props.match.params.merchantId
    const dispatch = useDispatch()

    // Sign in pop-up (with QR code)

    // State of Summary above tabs:
    const [error, setError] = useState<string | null>()

    const [isSyncingWithStripe, setIsSyncingWithStripe] =
        useState<boolean>(false)

    const [merchant, setMerchant] = useState<SupportMerchantApiDto>()
    const [merchantSummary, setMerchantSummary] =
        useState<SupportMerchantSummaryApiDto>()

    const [kycStatus, setKycStatus] = useState<MerchantKYCStatus>()
    const [activeTab, setActiveTab] = useState<MerchantTab>(merchant?.useFraudFramework ? 'kyb' : 'kyc')
    const [activeKYCTab, setActiveKYCTab] = useState<KYCTabNameType>(merchant?.useFraudFramework ? 'kyb' : 'review')
    const [activeRewardsTab, setActiveRewardsTab] = useState<RewardsTabNameType>('activity')

    const [externalAccounts, setExternalAccounts] = useState<
        ExternalAccountDto[] | null
    >(null)

    const [signInAsModalOpen, setSignInAsModalOpen] = useState(false)
    const [signInAsMagicLink, setSignInAsMagicLink] = useState<string | undefined>(undefined);

    useEffect(() => {
        window.scrollTo(0,0)
        loadMerchant()
        dispatch(fetchProfile())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (merchant?.useFraudFramework) {
            setActiveTab('kyb')
            setActiveKYCTab('kyb')
        } else {
            setActiveTab('kyc')
            setActiveKYCTab('review')
        }
    }, [merchant?.useFraudFramework])

    const loadMerchant = async (reloadSummary?: boolean) => {
        if (reloadSummary) {
            setMerchantSummary(undefined)
        }

        setMerchant(await supportFetchMerchant(merchantId))
        setMerchantSummary(await supportFetchMerchantSummary(merchantId))
        setKycStatus(await supportFetchMerchantKYCSummary(merchantId))
        setExternalAccounts(await supportListExternalAccounts(merchantId))
    }

    const syncWithStripe = async () => {
        setIsSyncingWithStripe(true)

        try {
            // Sync with Stripe, then update the merchant locally:
            setMerchant(await supportMerchantSyncWithStripe(merchantId))

            // Update KYC status after syncing with Stripe:
            setKycStatus(await supportFetchMerchantKYCSummary(merchantId))
        } catch (e) {
            alert('Could not sync with Stripe')
        }

        setIsSyncingWithStripe(false)
    }

    const accountOwner = () => {
        return merchant?.associatedUsers?.find((user) => user.isOwner)
    }

    const onAllowAmexChange = async (allowAmex: boolean) => {
        if (!merchant) return

        setError(null)
        try {
            await supportUpdateMerchantPaymentConfigDetails(merchantId, { allowAmex })
            setMerchant(
                {
                    ...merchant,
                    allowAmex,
                }
            )
        } catch (e) {
            setError('Sorry, there was a problem changing merchant\'s Amex acceptance')
        }
    }

    if (!merchant) {
        return (
            <React.Fragment>
                <div className="page-content">
                    <MetaTags>
                        <title>Your merchant</title>
                    </MetaTags>
                    <Container fluid>
                        <Alert color="warning">Loading details...</Alert>
                    </Container>
                </div>
            </React.Fragment>
        )
    }

    const toggle = (tab: MerchantTab) => {
        if (activeTab !== tab) {
            setActiveTab(tab)
        }
    }

    const copyString = (string: string) => {
      navigator.clipboard.writeText(string).then(
        function () {
        },
        function (err) {
        }
      );
    };

    const signInAsUser = async (userId: string) => {
        const { magicLink } = await supportGenerateSignInLink(userId)
        setSignInAsMagicLink(magicLink)
    }

    const merchantEmail = merchant.email || merchant.associatedUsers?.find(u => u.isOwner)?.email

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{merchant.name}</title>
                </MetaTags>
                <Container fluid>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardHeaderJustified>
                                    <div>
                                        <h4 className="mb-0">{merchant.name}</h4>
                                        <span className="text-muted font-size-13">
                                            {merchant.category?.name && (
                                                <span>{merchant.category.name} • </span>
                                            )}
                                           <a href='#' onClick={() => copyString(merchant.id)}>{merchant.id} <i className="bx bx-paste"></i></a>
                                        </span>
                                        {merchant?.finfareUserId && (
                                            <div className="text-muted font-size-13">
                                                Finfare User ID: <a href='#' onClick={() => copyString(merchant.finfareUserId!)}>{merchant.finfareUserId} <i className="bx bx-paste"></i></a>
                                            </div>
                                        )}
                                    </div>
                                    <div>
                                        <MerchantRiskScoreComponent riskScores={merchant?.riskScores} />
                                    </div>
                                </CardHeaderJustified>
                                <CardBody>
                                    {merchantSummary && (
                                        <ul className="list-unstyled mb-0 text-muted font-size-13">
                                            <li>
                                                <b>
                                                    {accountOwner()?.firstName}{' '}
                                                    {accountOwner()?.lastName}
                                                </b>{' '}
                                                signed up{' '}
                                                <ReactTimeAgo
                                                    date={
                                                        new Date(
                                                            merchant.createdAt
                                                        )
                                                    }
                                                    locale="en-US"
                                                />
                                            </li>
                                            {
                                                merchantEmail && (
                                                    <li>
                                                        <b>Email:</b> <a href='#' onClick={() => copyString(merchantEmail)}>{merchantEmail} <i className="bx bx-paste"></i></a>
                                                    </li>
                                                )
                                            }
                                            <li>
                                                <b>Tel:</b> <a href='#' onClick={() => copyString(merchant.phone)}>{merchant.phone} <i className="bx bx-paste"></i></a>
                                            </li>
                                            <li>
                                                <b>Statement descriptor: </b>
                                                <a
                                                    href='#'
                                                    onClick={() => copyString(merchant?.statementDescriptor || merchant?.statementDescriptorKana || merchant?.statementDescriptorKanji || '')}
                                                >
                                                    {merchant?.statementDescriptor || merchant?.statementDescriptorKana || merchant?.statementDescriptorKanji || '[None set]'} <i className="bx bx-paste"></i>
                                                </a>
                                            </li>
                                            {
                                                merchant?.salesTeamCode && (
                                                    <>
                                                        <b>Used sales team code: </b>
                                                        <a
                                                            href='#'
                                                            onClick={() => copyString(merchant?.salesTeamCode || '')}
                                                        >
                                                            {merchant?.salesTeamCode} <i className="bx bx-paste"></i>
                                                        </a>
                                                    </>
                                                )
                                            }
                                            {accountOwner()?.referredBy && (
                                                <li>
                                                    Referred by{' '}
                                                    {
                                                        accountOwner()
                                                            ?.referredBy
                                                            ?.firstName
                                                    }{' '}
                                                    {
                                                        accountOwner()
                                                            ?.referredBy
                                                            ?.lastName
                                                    }{' '}
                                                    (
                                                    {
                                                        accountOwner()
                                                            ?.referredBy?.code
                                                    }
                                                    )
                                                </li>
                                            )}

                                            <li>
                                                {priceFormat(
                                                    merchantSummary.lifetimeVolume
                                                )}{' '}
                                                lifetime volume on {' '}
                                                {merchant.accountType} account type
                                            </li>
                                            <li className='mt-3'>
                                                Onboarding progress
                                                <AccountSetupProgress
                                                    onboardingPercentage={merchantSummary?.onboardingPercentage ?? 0}
                                                />
                                            </li>
                                        </ul>
                                    )}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Card>
                                <CardHeader className="d-flex">
                                    <CardSubHeader>
                                        {merchantSummary ? (
                                            <h4 className="mb-0">
                                                {priceFormat(
                                                    merchantSummary.balances
                                                        .available
                                                )}
                                            </h4>
                                        ) : (
                                            <>Loading...</>
                                        )}
                                        <span className="text-muted font-size-13">
                                            Available Balance
                                        </span>
                                    </CardSubHeader>
                                    <Spacer />
                                    <CardSubHeader>
                                        {merchantSummary ? (
                                                <h4 className="mb-0">
                                                    {priceFormat(
                                                        merchantSummary.balances
                                                            .remainingRewardBalance
                                                    )}
                                                </h4>
                                            ) : (
                                                <>Loading...</>
                                            )}
                                            <span className="text-muted font-size-13">
                                                Fee-free balance
                                            </span>
                                        </CardSubHeader>
                                </CardHeader>

                                <CardBody>

                                <div className="text-muted font-size-13 mb-4">
                                        
                                    </div>

                                    {merchant?.paymentProviderId && (
                                        <button
                                            type="button"
                                            className="btn btn-light btn-sm m-1"
                                            onClick={() => {
                                                const url = `https://dashboard.stripe.com/connect/accounts/${merchant?.paymentProviderId}`
                                                window
                                                    .open(url, '_blank')
                                                    ?.focus()
                                            }}
                                        >
                                            <i className="fab fa-stripe font-size-16 align-middle me-2"></i>{' '}
                                            Stripe
                                        </button>
                                    )}
                                    <button
                                        type="button"
                                        className="btn btn-light btn-sm"
                                        onClick={() => {
                                            const url = `https://data.lopay.com/dashboard/4?merchant_id=${merchant?.id}`
                                            window.open(url, '_blank')?.focus()
                                        }}
                                    >
                                        <i className="fas fa-database font-size-16 align-middle me-2"></i>{' '}
                                        Metabase
                                    </button>

                                    <button
                                        type="button"
                                        onClick={async () => {
                                            if (!merchant.onboardingLink) {
                                                alert(
                                                    "This merchant doesn't have an onboarding link"
                                                )
                                                return
                                            }
                                            window.open(merchant.onboardingLink)
                                        }}
                                        className="btn btn-light btn-sm waves-effect waves-light m-1"
                                    >
                                        <i className="fas fa-file-image font-size-16 align-middle me-2"></i>{' '}
                                        Onboarding
                                    </button>


                                    <button
                                        type="button"
                                        onClick={async () => {
                                            setSignInAsModalOpen(true)
                                            setSignInAsMagicLink(undefined)
                                        }}
                                        className="btn btn-light btn-sm waves-effect waves-light m-1"
                                    >
                                        <i className="fas fa-user font-size-16 align-middle me-2"></i>{' '}
                                        Sign-in as
                                    </button>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Card>
                        <CardBody className="p-4">
                            <Row>
                                <Col md="3" className="b-r-1">
                                    <MerchantPageNavbar merchant={merchant} kycStatus={kycStatus} activeTab={activeTab} toggleTab={toggle} />
                                </Col>
                                <Col md="9">
                                    <TabContent
                                        activeTab={activeTab}
                                        className=""
                                    >
                                        <TabPane tabId="openBankingVrpConsents">
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="h4">
                                                        Open Banking VRP
                                                        Consents
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <div className="mb-4">
                                                        {merchantId && (
                                                            <ExternalAccountOpenBankingVrpTable
                                                                search={
                                                                    merchantId
                                                                }
                                                                payoutSchedule="instant"
                                                                status={
                                                                    undefined
                                                                }
                                                                minimumTotalPaymentUnitsSum={
                                                                    undefined
                                                                }
                                                                kybStatus={
                                                                    undefined
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                    <TabContent
                                        activeTab={activeTab}
                                        className=""
                                    >
                                        <TabPane tabId="payouts">
                                            <Card>
                                                <CardHeaderJustified>
                                                    <CardTitle className="h4">
                                                        Payouts
                                                    </CardTitle>
                                                    <TriggerPayout
                                                        merchantId={merchantId}
                                                        currencyCode={merchant?.currencyCode}
                                                        merchantName={
                                                            merchant?.name
                                                        }
                                                        payoutMade={() => loadMerchant()}
                                                    />
                                                </CardHeaderJustified>
                                                <CardBody>
                                                    <div className="mb-4">
                                                        {merchantId && (
                                                            <PayoutTable
                                                                payoutUpdated={() => {
                                                                    loadMerchant()
                                                                }}
                                                                merchantId={
                                                                    merchantId
                                                                }
                                                                startDate={
                                                                    undefined
                                                                }
                                                                endDate={
                                                                    undefined
                                                                }
                                                                currencyCode={
                                                                    undefined
                                                                }
                                                                payoutMechanism={
                                                                    undefined
                                                                }
                                                                status={
                                                                    undefined
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                    </TabContent>
                                    {
                                        merchant.useFraudFramework
                                            ?
                                            <TabContent
                                                activeTab={activeTab}
                                                className=""
                                            >
                                                <TabPane tabId="kyb">
                                                    <CardHeader className="align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">
                                                            KYB Status and Settings
                                                        </h4>
                                                        <div className="flex-shrink-0">
                                                            <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                                                                <NavItem>
                                                                    <NavLink
                                                                        to="#"
                                                                        className={classnames(
                                                                            {
                                                                                active:
                                                                                    activeKYCTab ===
                                                                                    'kyb',
                                                                            }
                                                                        )}
                                                                        onClick={() => {
                                                                            setActiveKYCTab(
                                                                                'kyb'
                                                                            )
                                                                        }}
                                                                    >
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="fas fa-home"></i>
                                                                    </span>
                                                                        <span className="d-none d-sm-block">
                                                                        KYB Status
                                                                    </span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink
                                                                        to="#"
                                                                        className={classnames(
                                                                            {
                                                                                active:
                                                                                    activeKYCTab ===
                                                                                    'payments',
                                                                            }
                                                                        )}
                                                                        onClick={() => {
                                                                            setActiveKYCTab(
                                                                                'payments'
                                                                            )
                                                                        }}
                                                                    >
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="far fa-user"></i>
                                                                    </span>
                                                                        <span className="d-none d-sm-block">
                                                                        Recent
                                                                        Terminal
                                                                        Payment
                                                                        Locations
                                                                    </span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        </div>
                                                    </CardHeader>

                                                    <TabContent
                                                        activeTab={activeKYCTab}
                                                    >
                                                        <TabPane tabId="kyb">
                                                            <SupportMerchantKYB merchantId={merchantId} />
                                                        </TabPane>


                                                        <TabPane tabId="payments">
                                                            <PaymentMap
                                                                isActive={activeKYCTab === 'payments'}
                                                                merchantId={merchantId}
                                                            />
                                                        </TabPane>
                                                    </TabContent>
                                                </TabPane>
                                            </TabContent>
                                            :
                                            <TabContent
                                                activeTab={activeTab}
                                                className=""
                                            >
                                                <TabPane tabId="kyc">
                                                    <CardHeader className="align-items-center d-flex">
                                                        <h4 className="card-title mb-0 flex-grow-1">
                                                            KYC Review
                                                        </h4>
                                                        <div className="flex-shrink-0">
                                                            <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                                                                <NavItem>
                                                                    <NavLink
                                                                        to="#"
                                                                        className={classnames(
                                                                            {
                                                                                active:
                                                                                    activeKYCTab ===
                                                                                    'review',
                                                                            }
                                                                        )}
                                                                        onClick={() => {
                                                                            setActiveKYCTab(
                                                                                'review'
                                                                            )
                                                                        }}
                                                                    >
                                                                <span className="d-block d-sm-none">
                                                                    <i className="fas fa-home"></i>
                                                                </span>
                                                                        <span className="d-none d-sm-block">
                                                                    KYC Status
                                                                </span>
                                                                    </NavLink>
                                                                </NavItem>
                                                                <NavItem>
                                                                    <NavLink
                                                                        to="#"
                                                                        className={classnames(
                                                                            {
                                                                                active:
                                                                                    activeKYCTab ===
                                                                                    'payments',
                                                                            }
                                                                        )}
                                                                        onClick={() => {
                                                                            setActiveKYCTab(
                                                                                'payments'
                                                                            )
                                                                        }}
                                                                    >
                                                                <span className="d-block d-sm-none">
                                                                    <i className="far fa-user"></i>
                                                                </span>
                                                                        <span className="d-none d-sm-block">
                                                                    Recent
                                                                    Terminal
                                                                    Payment
                                                                    Locations
                                                                </span>
                                                                    </NavLink>
                                                                </NavItem>
                                                            </Nav>
                                                        </div>
                                                    </CardHeader>

                                                    <TabContent
                                                        activeTab={activeKYCTab}
                                                    >
                                                        <TabPane tabId="review">
                                                            <SupportMerchantKYC
                                                                merchantId={merchantId}
                                                                merchant={merchant}
                                                                kycStatus={kycStatus}
                                                                setKycStatus={setKycStatus}
                                                                externalAccounts={externalAccounts}
                                                                setMerchant={setMerchant}
                                                                syncWithStripe={syncWithStripe}
                                                                isSyncingWithStripe={isSyncingWithStripe}
                                                            />
                                                        </TabPane>


                                                        <TabPane tabId="payments">
                                                            <PaymentMap
                                                                isActive={activeKYCTab === 'payments'}
                                                                merchantId={merchantId}
                                                            />
                                                        </TabPane>
                                                    </TabContent>
                                                </TabPane>
                                            </TabContent>

                                    }
                                    <TabContent
                                        activeTab={activeTab}
                                        className=""
                                    >
                                        <TabPane tabId="profile">
                                            <MerchantBusinessProfile merchant={merchant} syncWithStripeFn={syncWithStripe} isSyncingWithStripe={isSyncingWithStripe} />
                                            <div>
                                                {accountOwner()?.merchants
                                                    ?.length && <LinkedMerchants merchant={merchant} history={props.history} />}
                                                <Card>
                                                    <CardHeader>
                                                        <CardTitle className="h4">
                                                            Merchant config
                                                        </CardTitle>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <Form>
                                                            <FormGroup check>
                                                                <Label check>
                                                                    <Input
                                                                        type="checkbox"
                                                                        checked={merchant.allowAmex}
                                                                        onChange={async (e) => onAllowAmexChange(e.target.checked)}
                                                                        id="company-profile-allow-amex-input"
                                                                        validate={{
                                                                            required: {
                                                                                value: true,
                                                                            },
                                                                        }}
                                                                        placeholder=".form-control-sm"
                                                                    />
                                                                    Accepts payments using Amex
                                                                </Label>
                                                            </FormGroup>
                                                        </Form>
                                                    </CardBody>
                                                </Card>
                                            </div>

                                            <Col lg={6}>
                                                <AvForm>
                                                    <div>
                                                        {error ? (
                                                            <Alert color="danger">
                                                                {error}
                                                            </Alert>
                                                        ) : null}
                                                    </div>
                                                </AvForm>
                                            </Col>
                                        </TabPane>

                                        <TabPane tabId="onboarding">
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle className="h4">
                                                        Onboarding progress
                                                    </CardTitle>
                                                </CardHeader>
                                                <CardBody>
                                                    <SupportMerchantAccountSetup
                                                        merchantId={merchantId}
                                                        isActive={activeTab === 'onboarding'}
                                                    />
                                                </CardBody>
                                            </Card>
                                        </TabPane>

                                        <TabPane tabId="rewards">
                                            <Card>
                                                <CardHeader className="align-items-center d-flex">
                                                    <h4 className="card-title mb-0 flex-grow-1">
                                                        Rewards
                                                    </h4>
                                                    <div className="flex-shrink-0">
                                                        <Nav className="justify-content-end nav-tabs-custom rounded card-header-tabs">
                                                            <NavItem>
                                                                <NavLink
                                                                    to="#"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={classnames(
                                                                        {
                                                                            active: activeRewardsTab === 'activity',
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        setActiveRewardsTab(
                                                                            'activity'
                                                                        )
                                                                    }}
                                                                >
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="fas fa-home"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">
                                                                        Reward activity
                                                                    </span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    to="#"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={classnames(
                                                                        {
                                                                            active: activeRewardsTab === 'challenges',
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        setActiveRewardsTab(
                                                                            'challenges'
                                                                        )
                                                                    }}
                                                                >
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="far fa-user"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">
                                                                        Reward challenges
                                                                    </span>
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem>
                                                                <NavLink
                                                                    to="#"
                                                                    style={{ cursor: 'pointer' }}
                                                                    className={classnames(
                                                                        {
                                                                            active: activeRewardsTab === 'rewards',
                                                                        }
                                                                    )}
                                                                    onClick={() => {
                                                                        setActiveRewardsTab(
                                                                            'rewards'
                                                                        )
                                                                    }}
                                                                >
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="far fa-user"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">
                                                                        Rewards
                                                                    </span>
                                                                </NavLink>
                                                            </NavItem>
                                                        </Nav>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <TabContent activeTab={activeRewardsTab}>
                                                        <TabPane tabId="activity">
                                                            <SupportMerchantRewardActivity
                                                                merchantId={merchantId}
                                                                isActive={activeRewardsTab === 'activity'}
                                                            />
                                                        </TabPane>
                                                        <TabPane tabId="challenges">
                                                            <SupportMerchantRewardChallenges
                                                                merchant={merchant}
                                                                isActive={activeRewardsTab === 'challenges'} />
                                                        </TabPane>
                                                        <TabPane tabId="rewards">
                                                            <SupportMerchantRewards
                                                                merchant={merchant}
                                                                isActive={activeRewardsTab === 'rewards'}
                                                            />
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </TabPane>

                                        <TabPane tabId="payments">
                                            <SupportMerchantPayments
                                                isActive={activeTab === 'payments'}
                                                merchantId={merchantId}
                                                merchant={merchant}
                                                loadMerchant={loadMerchant}
                                            />
                                        </TabPane>
                                        <TabPane tabId="charges">
                                            <SupportMerchantCharges merchant={merchant} />
                                        </TabPane>
                                        <TabPane tabId="quickbooks">
                                            <SupportMerchantQuickbooks merchant={merchant} />
                                        </TabPane>
                                        <TabPane tabId="fees">
                                            <Row>
                                                <Col sm="12">
                                                    <CardText className="mb-0">
                                                        Etsy mixtape wayfarers,
                                                        ethical wes anderson
                                                        tofu before they sold
                                                        out mcsweeney's organic
                                                        lomo retro fanny pack
                                                        lo-fi farm-to-table
                                                        readymade. Messenger bag
                                                        gentrify pitchfork
                                                        tattooed craft beer,
                                                        iphone skateboard
                                                        locavore carles etsy
                                                        salvia banksy hoodie
                                                        helvetica. DIY synth PBR
                                                        banksy irony. Leggings
                                                        gentrify squid 8-bit
                                                        cred pitchfork.
                                                        Williamsburg banh mi
                                                        whatever gluten-free,
                                                        carles pitchfork
                                                        biodiesel fixie etsy
                                                        retro mlkshk vice blog.
                                                        Scenester cred you
                                                        probably haven't heard
                                                        of them, vinyl craft
                                                        beer blog stumptown.
                                                        Pitchfork sustainable
                                                        tofu synth chambray yr.
                                                    </CardText>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="sales">
                                            <Row>
                                                <Col sm="12">
                                                    <CardText className="mb-0">
                                                        Trust fund seitan
                                                        letterpress, keytar raw
                                                        denim keffiyeh etsy art
                                                        party before they sold
                                                        out master cleanse
                                                        gluten-free squid
                                                        scenester freegan cosby
                                                        sweater. Fanny pack
                                                        portland seitan DIY, art
                                                        party locavore wolf
                                                        cliche high life echo
                                                        park Austin. Cred vinyl
                                                        keffiyeh DIY salvia PBR,
                                                        banh mi before they sold
                                                        out farm-to-table VHS
                                                        viral locavore cosby
                                                        sweater. Lomo wolf
                                                        viral, mustache
                                                        readymade thundercats
                                                        keffiyeh craft beer
                                                        marfa ethical. Wolf
                                                        salvia freegan,
                                                        sartorial keffiyeh echo
                                                        park vegan.
                                                    </CardText>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="bankAccounts">
                                            <Row>
                                                <Col sm="12">
                                                    <CardText className="mb-0">
                                                        some bank accounts
                                                    </CardText>
                                                </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="products">
                                            <Row>
                                                <Col sm="12">
                                                    <CardText className="mb-0">
                                                        some products
                                                    </CardText>
                                                </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="team">
                                            <SupportMerchantTeam
                                                users={merchant.associatedUsers ?? []} 
                                                merchantName={merchant.name} />
                                        </TabPane>

                                        <TabPane tabId="paymentLinks">
                                            
                                            <PaymentRequestsSupportComponent merchant={merchant} />
                                        </TabPane>

                                        <TabPane tabId="recentActivity">
                                            <RecentActivitySupportComponent merchant={merchant} />
                                        </TabPane>

                                        <TabPane tabId="lopayCard">
                                            <LopayCardSupportComponent merchant={merchant} />
                                        </TabPane>

                                        <TabPane tabId="schedules">
                                            <MerchantScheduleComponent merchant={merchant} scheduleUpdated={() => loadMerchant()} />
                                        </TabPane>
                                        

                                        <TabPane tabId="referrals">
                                            {merchant && (
                                                <MerchantDiscountReferrals
                                                    owner={accountOwner()!}
                                                    merchant={merchant}
                                                />
                                            )}
                                        </TabPane>

                                        <TabPane tabId="workingCapital">
                                            {merchant && (
                                                <MerchantWorkingCapital
                                                    owner={accountOwner()!}
                                                    merchant={merchant}
                                                />
                                            )}
                                        </TabPane>

                                        <TabPane tabId="cashAdvances">
                                            {merchant && (
                                                <MerchantCashAdvances
                                                    isActive={activeTab === 'cashAdvances'}
                                                    merchantId={merchantId}
                                                />
                                            )}
                                        </TabPane>

                                        <TabPane tabId="hardware">
                                            <SupportMerchantHardware merchant={merchant} accountOwner={accountOwner()}/>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Modal
                        isOpen={signInAsModalOpen}
                        toggle={() => {
                            setSignInAsModalOpen(false)
                        }}
                        scrollable={true}
                    >
                        <div className="modal-header">
                            <h5 className="modal-title mt-0">
                                {merchant.name}
                            </h5>
                            <button
                                type="button"
                                onClick={() => setSignInAsModalOpen(false)}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div>
                                <div className="mb-4">
                                    <div>
                                        {
                                            // eslint-disable-next-line
                                            signInAsMagicLink && (
                                                <div className="mb-3">
                                                    <h5 className="font-size-14 mb-1">
                                                        Sign in as{' '}
                                                        <a
                                                            href="/#"
                                                            onClick={() =>
                                                                setSignInAsMagicLink(
                                                                    undefined
                                                                )
                                                            }
                                                        >
                                                            (hide)
                                                        </a>
                                                        :
                                                    </h5>
                                                    <QRCode
                                                        value={
                                                            signInAsMagicLink
                                                        }
                                                    />
                                                </div>
                                            )
                                        }

                                        <>
                                            <h5 className="font-size-14 mb-1">
                                                Users
                                            </h5>
                                            <div className="mb-3">
                                                <div className="table-responsive">
                                                    <Table className="table table-striped mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th>
                                                                    Name
                                                                </th>
                                                                <th>
                                                                    Email
                                                                </th>
                                                                <th>
                                                                    Options
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {merchant
                                                                ?.associatedUsers
                                                                ?.length ===
                                                                0 && (
                                                                <Badge className="me-2 bg-danger">
                                                                    Danger:
                                                                    This
                                                                    merchant
                                                                    has no
                                                                    users.
                                                                    Perhaps
                                                                    they
                                                                    have
                                                                    been
                                                                    deleted?
                                                                </Badge>
                                                            )}
                                                            {merchant?.associatedUsers?.map(
                                                                (user) => {
                                                                    return (
                                                                        <tr
                                                                            key={`${merchant?.id}=${user.id}`}
                                                                        >
                                                                            <th scope="row">
                                                                                {
                                                                                    user.firstName
                                                                                }{' '}
                                                                                {
                                                                                    user.lastName
                                                                                }
                                                                            </th>
                                                                            <td>
                                                                                {
                                                                                    user.email
                                                                                }
                                                                            </td>
                                                                            <td>
                                                                                <button
                                                                                    onClick={() =>
                                                                                        signInAsUser(
                                                                                            user.id
                                                                                        )
                                                                                    }
                                                                                    type="button"
                                                                                    className="btn btn-sm btn-secondary"
                                                                                >
                                                                                    Sign
                                                                                    in
                                                                                    as
                                                                                </button>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MerchantPage
